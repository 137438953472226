import {
  useState,
  useMemo,
  useRef,
  CSSProperties,
  useCallback,
  KeyboardEvent,
  useEffect,
} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Typography } from '@mui/material';
import Slider from 'react-slick';
import { BannerCardType } from '~/data/types/bannerCard/bannerCard.types';
import ArrowLeftNoLineIcon from '~/assets/icons/jsx/ArrowLeftNoLine';
import { useIsMobile } from '~/hooks/useIsMobile';
import { useSortObjectArr } from '~/hooks/useSortArr';
import { useWindowWidth } from '~/hooks/useWindowWidth';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import BannerCard from '../BannerCard';
import { BANNER_TYPE, BannerLocationType } from '~/data/consts';
import { ARIA_LABELS_RESOURCES, RESOURCES } from '~/resources/resources';
import { useContactUsPopup } from '~/hooks/useContactUsPopup';
import { EContactUsFlow } from '~/data/ContactUsPopup/enums';
import { KEYBOARD_TYPES } from '~/data/constants';
import { FONTS_SIZE_LABELS } from '~/data/ui/fontSizes.data';
import BlackFreesbeLogoIcon from '~/assets/images/jsx/blackFreesbeLogo';
import { useAppDispatch } from '~/redux-toolkit/store/hooks';
import { setGalleryToolTip } from '~/redux-toolkit/slices/tooltipSlice';
import classes, {
  HotFreesbe,
  rootBoxStyle,
  WIDER_MOBILE_WIDTH,
  initialContainer,
} from './useStyles';
import Loader from '../Loader';
import { LOADER_SIZE } from '../Loader/useStyles';
import { setCar } from '~/redux-toolkit/slices/carSlice';

interface BannerGalleryProps {
  banners: BannerCardType[];
  location: BannerLocationType;
  title?: string;
  largeTopPadding?: boolean;
}

const BannerGallery = ({
  banners,
  location,
  title,
  largeTopPadding = false,
}: BannerGalleryProps) => {
  const dispatch = useAppDispatch();
  const events = useEvents();
  const { openContactUsModal } = useContactUsPopup();
  const isWiderMobile = useIsMobile(WIDER_MOBILE_WIDTH);
  const [isReady, setIsReady] = useState(false);
  const isMobile = useIsMobile();
  const width = useWindowWidth();
  const slider = useRef<Slider>(null);
  const activeSlideRef = useRef<HTMLLIElement>(null);
  const nextSlideRef = useRef<HTMLLIElement>(null);
  const prevSlideRef = useRef<HTMLLIElement>(null);
  const handleSort = useSortObjectArr;

  const initialSlideIndex = isMobile ? 2 : 1;
  const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlideIndex);
  const [nextSlideIndex, setNextSlideIndex] = useState(
    (initialSlideIndex + 1) % banners.length,
  );
  const [prevSlideIndex, setPrevSlideIndex] = useState(
    (initialSlideIndex - 1 + banners.length) % banners.length,
  );
  const [leftSlideIndex, setLeftSlideIndex] = useState(
    (initialSlideIndex - 2 + banners.length) % banners.length,
  );

  useEffect(() => {
    const activeSlide = document.querySelector('.slick-current .banner-card');
    if (activeSlide) {
      activeSlide.setAttribute('tabIndex', '0');
    }
    setIsReady(true);
  }, [width]);

  const paddingSlides = useMemo(() => {
    if (width <= 380) return '16%';
    if (width >= 381 && width <= 399) return '18%';
    if (width >= 400 && width <= 420) return '20%';
    if (width >= 421 && width <= 440) return '21%';
    if (width >= 441 && width <= 470) return '23%';
    if (width >= 471 && width <= 499) return '24%';
    if (width >= 500 && width <= 530) return '26%';
    if (width >= 531 && width <= 550) return '27%';
    if (width >= 551 && width <= 600) return '28%';
    if (width >= 601 && width <= 650) return '30%';
    if (width >= 651 && width <= 690) return '31%';
    if (width >= 691 && width <= 729) return '32%';
    if (width >= 730 && width <= 764) return '33%';
    if (width >= 765 && width <= 779) return '34%';
    if (width >= 780 && width <= 820) return '2%';
    if (width >= 821 && width <= 870) return '5%';
    if (width >= 871 && width <= 900) return '7%';
    if (width >= 901 && width <= 997) return '35px';
    if (width >= 998 && width <= 1009) return '40px';
    if (width > 1009) return '85px';
    return '18%';
  }, [width]);

  const slidesToShow = useMemo(() => {
    if (width <= 480) return 1;
    if (width <= 768) return 1;
    if (width > 1000) return 3;
    return 3;
  }, [width]);

  const settings = useMemo(
    () => ({
      className: 'center',
      centerMode: true,
      infinite: true,
      centerPadding: paddingSlides,
      slidesToShow: slidesToShow,
      speed: 350,
      draggable: isWiderMobile,
      swipe: isWiderMobile,
      accessibility: false,
      initialSlide: initialSlideIndex,
    }),
    [isWiderMobile, paddingSlides, slidesToShow, initialSlideIndex],
  );

  const getScaleStyle = (index: number): CSSProperties => {
    const style: CSSProperties[] = [{ ...classes.slide }];

    switch (index) {
      case activeSlideIndex:
        style.push({ ...classes.activeSlide });
        break;
      case nextSlideIndex:
      case prevSlideIndex:
        style.push({ ...classes.nextSlide });
        break;
      case leftSlideIndex:
        style.push({ ...classes.leftSlide });
        break;
      default:
        style.push({ ...classes.rightSlide });
        break;
    }
    return style.reduce((acc, curr) => ({ ...acc, ...curr }));
  };

  const handleEventOnClick = useCallback(
    (banner: BannerCardType) => {
      dispatch(setCar(null));
      events.sendEvent(
        {
          action: EActionEvent.BANNER_CAROUSEL_CLICKED,
          category: ECategoryEvent.BANNER,
        },
        {
          bannerTitle: banner.title,
          businessLine: banner.lineOfBusiness,
          location: location,
        },
      );
    },
    [events, location, dispatch],
  );

  const bannersToShow = useMemo(() => {
    if (banners.length > 0) {
      const bannersByRank = handleSort([...banners], 'rank');
      const bannersToRevers = bannersByRank.slice(1); // handle rtl
      return [bannersByRank[0]].concat(bannersToRevers.reverse().flat());
    }
    return [];
  }, [banners, handleSort]);

  const handleBeforeChange = (oldIndex: number, newIndex: number) => {
    dispatch(setGalleryToolTip({ id: oldIndex, isOpen: false }));
    setActiveSlideIndex(newIndex);
    setPrevSlideIndex((newIndex - 1 + banners.length) % banners.length);
    setNextSlideIndex((newIndex + 1) % banners.length);
    setLeftSlideIndex((newIndex - 2 + banners.length) % banners.length);
  };

  const focusSlide = () => {
    const slides = document.querySelectorAll('.slick-slide .banner-card');
    if (slides) {
      slides.forEach((slide) => slide.setAttribute('tabIndex', '-1'));
    }
    const activeSlide = document.querySelector('.slick-current .banner-card');
    if (activeSlide) {
      activeSlide.setAttribute('tabIndex', '0');
    }
  };

  const handleEnterKeyPress = useCallback(() => {
    const banner = bannersToShow[activeSlideIndex];
    if (banner.bannerType === BANNER_TYPE.LINK && banner.bannerLink) {
      window.open(banner.bannerLink, '_blank');
    } else if (banner.bannerType === BANNER_TYPE.LEAD) {
      dispatch(setCar(null));
      events.sendEvent(
        {
          action: EActionEvent.LEAD_LOAD,
          category: ECategoryEvent.LEAD,
        },
        {
          leadLocation: location,
        },
      );
      openContactUsModal({
        flow: EContactUsFlow.LEAVE_DETAILS,
        campaignNumber: banner.leadDetails?.campaignNumber,
        lineOfBusiness: banner.leadDetails?.lineOfBusiness,
        subMedia: banner.leadDetails?.subMedia,
        note: banner.leadDetails?.note,
        leadLocation: location,
      });
    }
  }, [
    dispatch,
    activeSlideIndex,
    bannersToShow,
    events,
    location,
    openContactUsModal,
  ]);

  const handleCardKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (
        event.key === KEYBOARD_TYPES.ENTER ||
        event.key === KEYBOARD_TYPES.SPACE
      ) {
        dispatch(setGalleryToolTip({ id: null, isOpen: false }));
        event.preventDefault();
        handleEnterKeyPress();
      }
    },
    [dispatch, handleEnterKeyPress],
  );

  const handleKeyPressLeftArrow = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (
        event.key === KEYBOARD_TYPES.ENTER ||
        event.key === KEYBOARD_TYPES.SPACE
      ) {
        dispatch(setGalleryToolTip({ id: null, isOpen: false }));
        event.preventDefault();
        slider?.current?.slickPrev();
      }
      events.sendEvent({
        action: EActionEvent.BANNER_CAROUSEL_CLICKED_ARROW_L,
        category: ECategoryEvent.BANNER,
      });
    },
    [dispatch, events],
  );

  const handleKeyPressRightArrow = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (
        event.key === KEYBOARD_TYPES.ENTER ||
        event.key === KEYBOARD_TYPES.SPACE
      ) {
        dispatch(setGalleryToolTip({ id: null, isOpen: false }));
        event.preventDefault();
        slider?.current?.slickNext();
      }
      events.sendEvent({
        action: EActionEvent.BANNER_CAROUSEL_CLICKED_ARROW_R,
        category: ECategoryEvent.BANNER,
      });
    },
    [dispatch, events],
  );

  const titleWithoutLogo = useMemo(() => {
    if (title && title?.toLowerCase().includes('freesbe')) {
      return title.split('freesbe').join(' ');
    }
    return '';
  }, [title]);

  if (!isReady && width && isMobile) {
    return (
      <Box style={initialContainer}>
        {title && (
          <Typography
            variant="h2"
            sx={classes.title}
            fontFamily="fontFamily"
            fontWeight="800"
          >
            {titleWithoutLogo || title}
            {titleWithoutLogo && (
              <BlackFreesbeLogoIcon Component={HotFreesbe} />
            )}
          </Typography>
        )}
        <Typography style={{ marginBottom: '-17px' }}>
          {RESOURCES.LOADING_DEALS}
        </Typography>
        <Loader size={LOADER_SIZE.lg} />
      </Box>
    );
  }

  return (
    <Box sx={rootBoxStyle(largeTopPadding)}>
      <Box sx={classes.content}>
        {title && (
          <Typography
            variant="h2"
            sx={classes.title}
            fontFamily="fontFamily"
            fontWeight="800"
          >
            {titleWithoutLogo || title}
            {titleWithoutLogo && (
              <BlackFreesbeLogoIcon Component={HotFreesbe} />
            )}
          </Typography>
        )}
        <Box
          sx={{ ...classes.arrow, ...classes.arrowLeft }}
          onClick={() => {
            dispatch(setGalleryToolTip({ id: null, isOpen: false }));
            slider?.current?.slickPrev();
            events.sendEvent({
              action: EActionEvent.BANNER_CAROUSEL_CLICKED_ARROW_L,
              category: ECategoryEvent.BANNER,
            });
          }}
          tabIndex={0}
          onKeyDown={handleKeyPressLeftArrow}
          role="button"
          aria-label={`${ARIA_LABELS_RESOURCES.NEXT_CARD}${ARIA_LABELS_RESOURCES.GALLERY} ${title}`}
        >
          <ArrowLeftNoLineIcon />
        </Box>
        <Box
          sx={classes.slides}
          aria-label={`${ARIA_LABELS_RESOURCES.GALLERY},${title}`}
          onKeyDown={handleCardKeyPress}
        >
          <Slider
            ref={slider}
            {...settings}
            beforeChange={handleBeforeChange}
            afterChange={focusSlide}
          >
            {bannersToShow.map((banner, index) => (
              <Box
                key={banner.rank}
                ref={
                  index === activeSlideIndex
                    ? activeSlideRef
                    : index === nextSlideIndex
                    ? nextSlideRef
                    : index === prevSlideIndex
                    ? prevSlideRef
                    : null
                }
              >
                <BannerCard
                  sliderContent={{
                    activeSlideIndex,
                    nextSlideIndex,
                    prevSlideIndex,
                    index,
                    length: banners.length,
                  }}
                  banner={banner}
                  type="card"
                  customStyle={getScaleStyle(index)}
                  hovered
                  location={location}
                  customEventFunction={() => handleEventOnClick(banner)}
                  defaultTitleSize={FONTS_SIZE_LABELS.MEDIUM}
                  defaultDescriptionSize={FONTS_SIZE_LABELS.X_SMALL}
                  hideInfo={banner.preDesignedBanner}
                  ref={slider}
                />
              </Box>
            ))}
          </Slider>
        </Box>
        <Box
          sx={{ ...classes.arrow, ...classes.arrowRight }}
          onClick={() => {
            dispatch(setGalleryToolTip({ id: null, isOpen: false }));
            slider?.current?.slickNext();
            events.sendEvent({
              action: EActionEvent.BANNER_CAROUSEL_CLICKED_ARROW_R,
              category: ECategoryEvent.BANNER,
            });
          }}
          tabIndex={0}
          onKeyDown={handleKeyPressRightArrow}
          role="button"
          aria-label={`${ARIA_LABELS_RESOURCES.PREV_CARD}${ARIA_LABELS_RESOURCES.GALLERY} ${title}`}
        >
          <ArrowLeftNoLineIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default BannerGallery;
